.Footer {
  width: 330px;
  height: 64px;

  background: #29292b;
  border-radius: 0px 0px 10px 10px;
}

.SocialLinkBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  padding: 17px 37px 18px 37px;
}

.SocialLink {
  display: flex;
  font-size: 26px;
  color: #918E9B;
}

.SocialLink:hover {
  opacity: 0.7;
}