.Section {
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 20px;
}

.SectionHeader {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: left;

  color: #F5F5F5;

  margin-top: 0px;
  margin-bottom: 0px;
}

.SectionText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  text-align: left;

  color: #DCDCDC;

  margin: 0px;
}