.InfoSection {
  padding-bottom: 33px;
}

.Title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
  margin-bottom: 3px;
}

.SubTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #F3BF99;
  margin: 0px;
  margin-bottom: 6px;
  padding-bottom: 0px;
}

.LinkBox {
  padding-top: 0px;
  padding-bottom: 20px;
}

.Link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  text-decoration: none;
  text-align: center;
  color: #F5F5F5;
}

a.Link:hover {
  text-decoration: #5093E2 underline;
}

.Paragraph {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
}

.ButtonBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
  padding-left: 35px;
  padding-right: 35px;
}

.ButtonBar a {
  text-decoration: none;
}

.Button {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 13px 9px 11px;
  gap: 8px;
  width: 115px;
  height: 34px;
  /* shadow/sm */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  cursor: pointer;
}

.Button-White {
  /* white */
  background: #FFFFFF;
  /* gray/300 */
  border: 1px solid #D1D5DB;
}

button.Button-White:hover {
  background: #e6eaec;
}

.Button-Blue {
  background: #5093E2;
  border: 0px;
}

button.Button-Blue:hover {
  background: #3c8ae2;
}

.ButtonText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  /* identical to box height, or 114% */
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.ButtonText-White {
  color: #374151;
}

.ButtonText-Blue {
  color: #FFFFFF;
}