.BCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BCardPic {
  width: 330px;
  height: 330px;
  border-radius: 10px 10px 0px 0px;
}

.BCardInfoBox {
  align-items: center;
  width: 330px;
  max-height: 400px;
  background: #151515;
  padding: 24px 0px 24px 0px;
}